<template>
  <div>
    <header-slot>
      <template #actions>
        <b-row class="w-100 pb-2">
          <b-col>
            <b-button
              v-show="isModuleActualEqualTwo"
              v-if="!isOnlyLead"
              variant="success"
              @click="openModalSearch()"
            >
              <feather-icon
                icon="SearchIcon"
                size="15"
                class="mr-50 text-white"
              />Payments Search
            </b-button>
          </b-col>
        </b-row>
      </template>
    </header-slot>
    <!-- <b-nav card-header pills class="m-0">
        <b-nav-item
          :to="{ name: 'list-clients' }"
          exact
          exact-active-class="active"
          :link-classes="['px-3',bgTabsNavs]"
        >ALL CLIENTS</b-nav-item>

      </b-nav>
    <router-view :key="$route.name"></router-view> -->

    <clients-list />
    <modal-search-payment
      v-if="openModalSearchPayment"
      @close="closeModalSearch"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import chargeBackService from "@/views/commons/components/charge-back_v2/service/charge.back.service";
import ModalSearchPayment from "@/views/commons/components/charge-back_v2/view/modal/ModalSearchPayment.vue";

import ClientsList from "./clients-module/ClientsList.vue";

export default {
  name: "BankOfFlyers",
  components: {
    "clients-list": ClientsList,
    ModalSearchPayment,
  },
  data() {
    return {
      openModalSearchPayment: false,
      isOnlyLead: false,
      moduleActual: null,
      permitStatus: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
    }),
    isModuleActualEqualTwo() {
      return this.permitStatus === 1;
    },
  },
  async created() {
    await Promise.all([this.moduleId()]);
  },
  mounted() {
    this.getAccessModules();
  },
  methods: {
    openModalSearch() {
      this.openModalSearchPayment = true;
    },
    closeModalSearch() {
      this.openModalSearchPayment = false;
    },
    async getAccessModules() {
      try {
        const params = {
          module_actual: this.moduleActual,
        };
        const result = await chargeBackService.getAccessModule(params);
        this.permitStatus = result[0]?.permit_status;
      } catch (error) {
        console.error(error);
      }
    },
    moduleId() {
      this.moduleActual = this.$route.matched[0].meta.module;
    },
  },
};
</script>

<style scoped>
</style>
